import React from "react";

// styling
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { keyframes } from "@emotion/react";
import { mediaQuery } from "../styles/global.js"
import "../styles/holidayCard.css";

const CardPage2021 = () => {
    return (
            <>
                <ContainerSVG>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" css={svgContent}>
                        <path fill="#e2ddd7" id="Background" d="M0 0H1920V1080H0z">
                        </path>
                        <g id="Snowflakes">
                            <animate attributeName="opacity"
                                attributeType="XML"
                                values="1; 0.3; 1"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                            <path
                                className="cls-2"
                                d="M259.66 -22.2L341.47 -3.51 393.98 -40.96 373.1 34.05 62.08 -36.27 347.99 -83.19 347.99 -40.96 327.58 -56.6 259.66 -22.2z"
                            />
                            <path
                                className="cls-2"
                                d="M242.83 49.42L324.55 68.5 355.32 125.18 369.44 48.61 59.08 -24.55 295.48 142.97 313.99 105.02 288.79 110.12 242.83 49.42z"
                            />
                            <path
                                className="cls-2"
                                d="M-189.88 -50.76L-271.69 -69.45 -324.2 -32 -303.32 -107 7.7 -36.69 -278.21 10.23 -278.21 -32 -257.8 -16.36 -189.88 -50.76z"
                            />
                            <path
                                className="cls-2"
                                d="M-134.17 112.59L-205.24 157.23 -215.88 220.84 -254.16 153.03 15.49 -17.17 -153.5 218.18 -183.36 188.32 -157.87 184.94 -134.17 112.59z"
                            />
                            <path
                                className="cls-2"
                                d="M-172.92 50.05L-244.19 94.35 -306.03 76.03 -261.87 140.15 9.32 -27.58 -276.29 21.13 -262.54 61.06 -248.33 39.63 -172.92 50.05z"
                            />
                            <path
                                className="cls-2"
                                d="M18.11 188.37L-0.58 270.18 36.88 322.69 -38.13 301.81 32.19 -9.21 79.1 276.7 36.88 276.7 52.52 256.28 18.11 188.37z"
                            />
                            <path
                                className="cls-2"
                                d="M-53.51 171.54L-72.58 253.26 -129.26 284.03 -52.69 298.15 20.46 -12.21 -147.05 224.19 -109.1 242.7 -114.21 217.5 -53.51 171.54z"
                            />
                            <path
                                className="cls-2"
                                d="M181.46 132.66L226.1 203.72 289.71 214.37 221.91 252.64 51.71 -17 287.05 151.99 257.19 181.85 253.82 156.35 181.46 132.66z"
                            />
                            <path
                                className="cls-2"
                                d="M118.92 171.4L163.22 242.67 144.9 304.51 209.03 260.35 41.29 -10.84 90 274.77 129.93 261.03 108.5 246.82 118.92 171.4z"
                            />
                            <path
                                className="cls-2"
                                d="M1996.26 546.49L1922.4 412.62 1846.22 535.57 1885.39 496.85 1896.47 666.56 1950.21 494.27 1996.26 546.49z"
                            />
                            <path
                                className="cls-2"
                                d="M1836.23 540.21L1911.12 406.91 1766.49 406.91 1820.03 419.81 1681.6 518.61 1856.36 473.56 1836.23 540.21z"
                            />
                            <path
                                className="cls-2"
                                d="M1814.27 397.24L1911.22 397.12 1866.19 317.22 1875.73 350.81 1778.06 305.1 1857.35 387.62 1814.27 397.24z"
                            />
                            <path
                                className="cls-2"
                                d="M1844.61 260.46L1918.47 394.33 1994.65 271.39 1955.49 310.11 1944.41 140.4 1890.66 312.68 1844.61 260.46z"
                            />
                            <path
                                className="cls-3"
                                d="M1917.6 1055.01L1978.15 1068.84 2017 1041.13 2001.55 1096.63 1771.39 1044.6 1982.97 1009.88 1982.97 1041.13 1967.86 1029.55 1917.6 1055.01z"
                            />
                            <path
                                className="cls-3"
                                d="M1905.15 1108.01L1965.63 1122.13 1988.4 1164.07 1998.84 1107.41 1769.17 1053.27 1944.11 1177.23 1957.81 1149.15 1939.16 1152.93 1905.15 1108.01z"
                            />
                            <path
                                className="cls-3"
                                d="M1876.38 934.13L1928.97 901.1 1936.84 854.03 1965.17 904.2 1765.63 1030.15 1890.68 856 1912.78 878.09 1893.91 880.59 1876.38 934.13z"
                            />
                            <path
                                className="cls-3"
                                d="M1905.05 980.41L1957.79 947.63 2003.55 961.19 1970.87 913.74 1770.19 1037.86 1981.55 1001.81 1971.37 972.27 1960.86 988.13 1905.05 980.41z"
                            />
                            <path
                                className="cls-3"
                                d="M1763.69 878.06L1777.52 817.51 1749.8 778.66 1805.31 794.11 1753.28 1024.27 1718.56 812.69 1749.8 812.69 1738.23 827.8 1763.69 878.06z"
                            />
                            <path
                                className="cls-3"
                                d="M1816.69 890.51L1830.8 830.03 1872.75 807.27 1816.09 796.82 1761.95 1026.49 1885.91 851.55 1857.83 837.85 1861.61 856.5 1816.69 890.51z"
                            />
                            <path
                                className="cls-3"
                                d="M1642.81 919.28L1609.78 866.69 1562.7 858.82 1612.88 830.49 1738.83 1030.03 1564.67 904.98 1586.77 882.88 1589.27 901.75 1642.81 919.28z"
                            />
                            <path
                                className="cls-3"
                                d="M1689.09 890.61L1656.31 837.87 1669.87 792.11 1622.41 824.79 1746.53 1025.47 1710.49 814.12 1680.94 824.29 1696.8 834.8 1689.09 890.61z"
                            />
                            <path
                                className="cls-3"
                                d="M1584.94 1033.87L1524.4 1020.04 1485.55 1047.76 1501 992.25 1731.15 1044.29 1519.58 1079.01 1519.58 1047.76 1534.68 1059.33 1584.94 1033.87z"
                            />
                            <path
                                className="cls-3"
                                d="M1597.4 980.88L1536.92 966.76 1514.15 924.82 1503.7 981.48 1733.37 1035.61 1558.44 911.65 1544.74 939.74 1563.39 935.96 1597.4 980.88z"
                            />
                            <path
                                className="cls-3"
                                d="M1626.17 1154.76L1573.58 1187.79 1565.7 1234.86 1537.38 1184.68 1736.92 1058.74 1611.86 1232.89 1589.77 1210.8 1608.63 1208.3 1626.17 1154.76z"
                            />
                            <path
                                className="cls-3"
                                d="M1597.5 1108.47L1544.76 1141.26 1499 1127.7 1531.67 1175.15 1732.35 1051.03 1521 1087.08 1531.17 1116.62 1541.69 1100.76 1597.5 1108.47z"
                            />
                            <path
                                className="cls-3"
                                d="M1738.86 1210.83L1725.03 1271.37 1752.74 1310.23 1697.24 1294.78 1749.27 1064.62 1783.99 1276.19 1752.74 1276.19 1764.32 1261.09 1738.86 1210.83z"
                            />
                            <path
                                className="cls-3"
                                d="M1685.86 1198.38L1671.74 1258.85 1629.8 1281.62 1686.46 1292.07 1740.6 1062.4 1616.64 1237.34 1644.72 1251.04 1640.94 1232.38 1685.86 1198.38z"
                            />
                            <path
                                className="cls-3"
                                d="M1859.74 1169.61L1892.77 1222.19 1939.84 1230.07 1889.67 1258.39 1763.72 1058.86 1937.87 1183.91 1915.78 1206.01 1913.28 1187.14 1859.74 1169.61z"
                            />
                            <path
                                className="cls-3"
                                d="M1813.46 1198.28L1846.24 1251.02 1832.68 1296.78 1880.13 1264.1 1756.01 1063.42 1792.06 1274.77 1821.6 1264.6 1805.74 1254.09 1813.46 1198.28z"
                            />
                            <path
                                className="cls-2"
                                d="M660.77 1256.67L845.91 1118.05 645.8 1029.6 711.99 1080.19 460.05 1132.23 729.39 1176.77 660.77 1256.67z"
                            />
                            <path
                                className="cls-2"
                                d="M636.76 1015.59L852.11 1099.95 821.9 883.27 813.76 966.18 636.82 779.42 740.82 1031.84 636.76 1015.59z"
                            />
                            <path
                                className="cls-2"
                                d="M846.37 952.83L866.81 1098.06 977.1 1013.91 928.77 1035.22 976.85 879.33 869.79 1015.37 846.37 952.83z"
                            />
                            <path
                                className="cls-2"
                                d="M1057.62 969.72L872.49 1108.34 1072.59 1196.79 1006.41 1146.2 1258.35 1094.16 989.01 1049.62 1057.62 969.72z"
                            />
                            <path
                                className="cls-3"
                                d="M125.09 1067.69L156.12 1074.78 176.03 1060.57 168.12 1089.02 50.16 1062.35 158.59 1044.56 158.59 1060.57 150.85 1054.64 125.09 1067.69z"
                            />
                            <path
                                className="cls-3"
                                d="M118.71 1094.85L149.71 1102.09 161.37 1123.58 166.73 1094.54 49.03 1066.8 138.68 1130.33 145.7 1115.93 136.14 1117.87 118.71 1094.85z"
                            />
                            <path
                                className="cls-3"
                                d="M103.97 1005.74L130.92 988.81 134.95 964.69 149.47 990.4 47.21 1054.95 111.3 965.7 122.62 977.02 112.95 978.3 103.97 1005.74z"
                            />
                            <path
                                className="cls-3"
                                d="M118.66 1029.46L145.69 1012.66 169.14 1019.61 152.39 995.29 49.55 1058.9 157.86 1040.43 152.65 1025.29 147.26 1033.41 118.66 1029.46z"
                            />
                            <path
                                className="cls-3"
                                d="M46.22 977L53.3 945.98 39.1 926.07 67.55 933.98 40.88 1051.93 23.09 943.51 39.1 943.51 33.17 951.25 46.22 977z"
                            />
                            <path
                                className="cls-3"
                                d="M73.38 983.39L80.61 952.39 102.11 940.72 73.07 935.37 45.32 1053.07 108.85 963.42 94.46 956.4 96.4 965.96 73.38 983.39z"
                            />
                            <path
                                className="cls-3"
                                d="M-15.73 998.13L-32.66 971.18 -56.79 967.14 -31.07 952.63 33.48 1054.89 -55.78 990.8 -44.45 979.48 -43.17 989.14 -15.73 998.13z"
                            />
                            <path
                                className="cls-3"
                                d="M7.99 983.44L-8.82 956.41 -1.87 932.96 -26.19 949.7 37.42 1052.55 18.95 944.24 3.81 949.45 11.94 954.84 7.99 983.44z"
                            />
                            <path
                                className="cls-3"
                                d="M-45.39 1056.86L-76.42 1049.77 -96.33 1063.97 -88.41 1035.53 29.54 1062.2 -78.89 1079.99 -78.89 1063.97 -71.15 1069.91 -45.39 1056.86z"
                            />
                            <path
                                className="cls-3"
                                d="M-39.01 1029.7L-70 1022.46 -81.67 1000.97 -87.02 1030.01 30.68 1057.75 -58.97 994.22 -65.99 1008.61 -56.44 1006.68 -39.01 1029.7z"
                            />
                            <path
                                className="cls-3"
                                d="M-24.26 1118.81L-51.21 1135.74 -55.25 1159.86 -69.77 1134.15 32.49 1069.6 -31.59 1158.85 -42.92 1147.53 -33.25 1146.25 -24.26 1118.81z"
                            />
                            <path
                                className="cls-3"
                                d="M-38.95 1095.09L-65.98 1111.89 -89.44 1104.94 -72.69 1129.26 30.16 1065.65 -78.16 1084.12 -72.95 1099.26 -67.56 1091.14 -38.95 1095.09z"
                            />
                            <path
                                className="cls-3"
                                d="M33.49 1147.55L26.4 1178.57 40.61 1198.48 12.16 1190.57 38.83 1072.62 56.62 1181.04 40.61 1181.04 46.54 1173.3 33.49 1147.55z"
                            />
                            <path
                                className="cls-3"
                                d="M6.33 1141.16L-0.91 1172.16 -22.4 1183.83 6.64 1189.18 34.38 1071.48 -29.15 1161.13 -14.76 1168.15 -16.69 1158.59 6.33 1141.16z"
                            />
                            <path
                                className="cls-3"
                                d="M95.44 1126.42L112.37 1153.37 136.49 1157.4 110.78 1171.92 46.23 1069.66 135.48 1133.75 124.16 1145.07 122.88 1135.4 95.44 1126.42z"
                            />
                            <path
                                className="cls-3"
                                d="M71.72 1141.11L88.52 1168.14 81.57 1191.59 105.89 1174.85 42.28 1072 60.75 1180.32 75.89 1175.1 67.77 1169.71 71.72 1141.11z"
                            />
                            <path
                                className="cls-2"
                                d="M141.79 594.91L10 668.78 131.98 743.24 93.47 704.8 261.09 692.73 90.5 640.76 141.79 594.91z"
                            />
                            <path
                                className="cls-2"
                                d="M86.71 726.8L4.87 676.99 1.72 767.56 10.96 734.31 69.81 823.16 45.41 712.74 86.71 726.8z"
                            />
                            <path
                                className="cls-2"
                                d="M10.05 507.22L3.43 658.15 130.97 593.68 78.01 606.17 156.04 457.33 22.01 574.97 10.05 507.22z"
                            />
                            <path
                                className="cls-2"
                                d="M1818.88 -22.29L1859.5 -12.81 1874.79 15.36 1881.81 -22.7 1727.56 -59.05 1845.05 24.2 1854.25 5.34 1841.72 7.88 1818.88 -22.29z"
                            />
                            <path
                                className="cls-2"
                                d="M1631.52 9.1L1596.2 31.29 1590.91 62.9 1571.89 29.2 1705.9 -55.38 1621.91 61.58 1607.07 46.74 1619.74 45.06 1631.52 9.1z"
                            />
                            <path
                                className="cls-2"
                                d="M1612.26 -21.98L1576.84 0.04 1546.11 -9.07 1568.06 22.8 1702.83 -60.56 1560.89 -36.35 1567.72 -16.51 1574.78 -27.16 1612.26 -21.98z"
                            />
                            <path
                                className="cls-2"
                                d="M1707.2 46.76L1697.91 87.42 1716.53 113.52 1679.25 103.14 1714.19 -51.43 1737.51 90.66 1716.53 90.66 1724.3 80.52 1707.2 46.76z"
                            />
                            <path
                                className="cls-2"
                                d="M1671.6 38.4L1662.13 79.01 1633.96 94.31 1672.01 101.32 1708.37 -52.92 1625.12 64.56 1643.98 73.76 1641.44 61.24 1671.6 38.4z"
                            />
                            <path
                                className="cls-2"
                                d="M1788.38 19.07L1810.57 54.39 1842.18 59.68 1808.48 78.7 1723.9 -55.3 1840.86 28.68 1826.02 43.52 1824.34 30.85 1788.38 19.07z"
                            />
                            <path
                                className="cls-2"
                                d="M1757.3 38.33L1779.32 73.75 1770.21 104.48 1802.08 82.54 1718.72 -52.24 1742.93 89.7 1762.77 82.87 1752.12 75.81 1757.3 38.33z"
                            />
                            <path
                                className="cls-3"
                                d="M989.56 192.78L1073.92 -22.56 857.24 7.65 940.15 15.79 753.39 192.73 1005.81 88.73 989.56 192.78z"
                            />
                            <path
                                className="cls-3"
                                d="M1247.53 -42.48L1102.3 -22.04 1186.45 88.26 1165.14 39.92 1321.03 88.01 1184.99 -19.06 1247.53 -42.48z"
                            />
                            <path
                                className="cls-3"
                                d="M1230.64 168.78L1092.02 -16.36 1003.57 183.75 1054.16 117.56 1106.2 369.5 1150.74 100.16 1230.64 168.78z"
                            />
                        </g>
                        <g id="Lights">
                            <path
                                className="cls-4"
                                css={colorFlash}
                                d="M947.46 1080s43.78-58.31 35.66-111.22l-27.77-7.72s-25.51 16-7.89 118.94zM1034.55 198.31S1008.3 87.56 988 92.66l-23.68 18.54s27.54 74.58 70.23 87.11zM164.39 415.35s-25.7-60-76.34-79l-16.29 27.24s27.02 49.05 92.63 51.76z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#11a67e;#0b674e;#11a67e"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-5"
                                css={colorFlash}
                                d="M1079.7 901.32s61.14 30.79 102.14 5.81l-2-33s-20.91-23.42-100.14 27.19zM807.13 0s-70.66 80.44-57 98.56l25.47 15S818.44 49.38 807.13 0zM1.4 666.87s52 29.08 102 8.08l-1.51-33.09S54.66 622.34 1.4 666.87z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#f1322e;#ba2320;#f1322e"
                                    keyTimes="0; .33; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-6"
                                css={colorFlash}
                                d="M30.64 994.4s66.55 4.33 97.57-35.58c-12-12.45-11.79-30.18-11.79-30.18S89.88 915.1 30.64 994.4zM1722.09 256.34s-25.93-110.85-46.26-105.83L1652.11 169s27.33 74.64 69.98 87.34zM504.49 238.58s57.55-4.76 92-51.87L581 158.88s-49.39 10.33-76.51 79.7z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#fdc157;#e6a737;#fdc157"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-7"
                                css={colorFlash}
                                d="M1262.59 40.15s-40.45 61.47-29.4 113.65l28.14 5.63s24.56-17.87 1.26-119.28zM652.23 857.39s14.53 113.61 35.22 111.4l25.43-15.08s-19.58-77.9-60.65-96.32zM0 48.12s22.4 61.71 71.91 84.38l17.73-26S65.33 55.61 0 48.12z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#fe8e6b;#eb5e31;#fe8e6b"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-8"
                                css={colorFlash}
                                d="M1504.72 209.37s-14.29-75.53-55.76-99.47l-21.88 21.28s-4.08 33.3 77.64 78.19zM342.88 1038.27s25.06-111.12 5.28-118.51l-28.49 2.3s-8.34 80.56 23.21 116.21zM419 43.43S365 67 343.62 123.58l21.69 21.56S410.09 118.9 419 43.43z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#7bf1f8;#70c5ca;#7bf1f8"
                                    keyTimes="0; 0.5; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-6"
                                css={colorFlash}
                                d="M1339 813.75s16.91 113.18 37.55 110.38l25.1-15.8S1380.43 831 1339 813.75z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#fdc157;#e6a737;#fdc157"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-7"
                                css={colorFlash}
                                d="M1880.21 862.56s-65.23-15.84-10Join us to celebrate theholiday season"
                                d="M1576.73 1010.3s61.32-30.31 73.9-82l-22.09-21s-28.96 1.45-51.81 103z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#fe8e6b;#eb5e31;#fe8e6b"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-4"
                                d="M1920 138.07s-99 2.66-99.64 26.76l9.75 31.14s69.05-12.89 89.89-57.9z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#11a67e;#0b674e;#11a67e"
                                    keyTimes="0; 0.50; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                className="cls-5"
                                css={colorFlash}
                                d="M1737.52 576.5s94.61 33.82 101.82 11.2l-.76-33.12s-69.01-13.12-101.06 21.92z"
                            >
                                <animate attributeName="fill"
                                    attributeType="XML"
                                    type="flash"
                                    values="#f1322e;#ba2320;#f1322e"
                                    keyTimes="0; .33; 1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                d="M1835.79 220.11c-8.33-37.61-24.78-112-65.61-123.59-31-8.84-51.92 23.56-102.65 61.79-74.12 55.88-198 92.6-260.87 36.72-36-32-53.4-96.26-38.2-115.07 16.61-20.57 79.08 4.51 79.5 25.07.51 25.11-91 65.06-178.29 55.52-96.41-10.54-119.24-74-203.76-85.07C972 63.2 961 154.52 868.33 148.91c-88.71-5.37-112.7-81.72-196-66.27-42.65 7.91-94 38.58-93.39 70.75.49 26.89 37.25 56 53.26 46.57 21.78-12.77 19.18-109.43-15.44-131.65-50.69-32.5-128.85 120.3-220.76 92.24-51-15.55-60.52-72.83-109.61-70.74-33.1 1.4-37.82 28.86-92.62 57.31-46.53 24.16-113.31 41.13-125 18.81-9.83-18.73 17.76-67.56 34.73-64.48 17.92 3.25 26.7 64.85 26.24 107.46-1.22 114-70.46 128.93-78.72 227.46-8.66 103.25 59.71 128.49 50.94 257C98.09 749.66 79.31 828 51 831.3 27.87 834-3 787 5.45 764.14c11.32-30.48 99.66-36.16 128.12 7.16 33.91 51.61-39 140.52-10 181.79 29.2 41.56 110.73-37.75 246.18-33.09 139.18 4.74 167.7 92.4 274.76 64.47 86.77-22.67 130.84-96.53 158.22-73.47 23.13 19.49 16.81 94.49-6.18 103.88-19 7.75-53.18-19-50.94-36.72 3.49-27.53 98.39-47.58 178.29-26.86 23.63 6.13 33.28 12.54 70.23 20.6 52.9 11.53 87.89 10.33 98 9.85 31.57-1.52 49-2.36 70.24-12.54 39.4-18.92 80.48-64.73 71.78-94.93-6.13-21.25-37-35.1-47.86-25.07-15.85 14.61-3.82 96.17 34 124.48 80.48 60.31 222.07-162.75 356.58-103.88 52.87 23.13 63.05 71.62 130.43 85.07 65.29 13 114.16-50.66 134.3-27.76 14.05 16 5.52 59.74-6.18 62.69-15.08 3.8-49-56.81-57.11-117.32-13.14-98.32 51.08-128.33 59.43-262.38 1.37-22 8.3-133.39-39.37-163-20.32-12.62-50.46-10.3-55.57 2.69-7.1 18.07 29.29 69.51 55.57 64.47 42.63-8.15 65.77-166.06 37.42-294.16z"
                                fill="none"
                                stroke="#658080"
                                strokeMiterlimit={10}
                                strokeWidth="5px"
                            />
                        </g>
                        <g id="Dog">
                            <path
                                className="cls-10"
                                d="M875.65 454.62s3.41-24.89-13.65-29.51-43 4.26-64.84 42.48c6.14 21.84 7.51 58 5.46 88.73 32.76-4.1 24.57-84 24.57-84s33.11-23.83 48.46-17.7zM1049 654.61c-25.26-71.67-101-63.48-109.21-77.81 32.08-26.62 4.78-51.2-2-52.56-64.23-12.31-44.36-36.86-51.87-50.51-8.92-43.68 19.08-53.24 28.64-57.33 65.52-23.21 115.35 8.87 115.35 8.87 21.16 20.48-4.1 32.08 10.92 67.57-10.92 30 7.51 49.83 10.92 63.48 2.73 10.92 4.09 70.98-2.75 98.29zM857.91 527.65s18.42-16.38 40.27-2.73c.68 17.75-22.53 47.78-40.27 2.73z"
                            />
                            <path
                                className="cls-10"
                                d="M875.65 454.62s10.92 47.1-22.52 69.62c-2.73 25.94-14.34 31.4 10.24 68.94-20.48 26.62-36.18 39.58-21.85 59.38-22.52-6.83-44.36-49.14-38.9-96.24 5.46-34.13 8.87-66.89 26.62-87.37 12.97-12.95 32.76-27.3 46.41-14.33z"
                            />
                            <path
                                className="cls-10"
                                d="M883.16 562.46s-11.6 10.92-21.16 14.34c9.56 1.36 16.38-4.89 21.16-5.52 19.79 1.47 31 5.22 45.89 5.71-18.75-7.9-35.65-7.7-45.89-14.53z"
                            />
                            <path
                                className="cls-11"
                                d="M922.75 469s25.33-8.19 43.38 0c-5.54-13-11.3-11.6-11.3-11.6s-12.29 1.32-32.08 11.6zM862 482.36s-6.82-7.95-19.79 5c7.51-11.6 9.89-12.94 9.89-12.94s9.22 3.58 9.9 7.94zM993.05 531.75s7.51 36.86-19.79 42.32c47.77 13.65 41 7.5 56.65 19.11-1.37-20.48-25.91-39.59-36.86-61.43zM841.52 565.88s8.2 19.11 17.75 26.61c-16.38 7.51-26.62 12.29-32.08 17.07-7.51-14.34 7.81-25.74 14.33-43.68z"
                            />
                            <path
                                className="cls-10"
                                d="M884.87 716.38s12.28 31.21 43 60.65c-10.24-22.34-17.3-21.57-10.24-24.48 5.8-2.39-32.76-36.17-32.76-36.17zM941.86 787.7s4.78-36.17 75.08-82.59c-31.4 27.99-50.51 44.37-75.08 82.59z"
                            />
                            <path
                                className="cls-5"
                                d="M772 412.39s139.83-165.26 266.1-101.11c0 0 25.93 9.56 36.17 75.77 3.71 23.95 34.47 100.67 34.47 100.67s-34.23 34.11-71-34.48c-.4-.75 2.45-19.78-2.33-17.73-35.77-66-42-36.52-42-36.52z"
                            />
                            <path
                                className="cls-12"
                                d="M790.08 471s57-74.64 247.19-35.42c26.91 7.46 47.38-18.33 28.37-30.85s-182.26-87.5-313.25 22.68c-1.93 33.99.92 42.46 37.69 43.59zM1108.74 487.72s-10.58-7.16-24.91 10.58 4 33 4 33 36.28 23 39-2.27-2.73-35.5-18.09-41.31z"
                            />
                        </g>
                        <g id="text">
                            <text className="cls-13" transform="translate(865 860)">
                                <RSVP href="mailto:matthew@mreyes.info?subject=Holiday Party&body=Thanks for the invite, I'll be there! Sounds like fun.">{"RSVP"}</RSVP>
                            </text>
                            <text
                                transform="translate(1200 321.51)"
                                fontSize="48px"
                                fontFamily="Rumba-Large,Rumba"
                                fill="#002923"
                            >
                                {"Join us to celebrate the"}
                                <tspan x={74.64} y={57.6}>
                                    {"holiday season."}
                                </tspan>
                                <tspan fontSize="60px" fill="#ba2320">
                                    <tspan x={0} y={201.6}>
                                        {"December 11, 2021"}
                                    </tspan>
                                    <tspan x={140} y={273.6} letterSpacing="-.03em">
                                        {"6:15 pm"}
                                    </tspan>
                                </tspan>
                                <tspan fontSize="36px">
                                    <tspan x={-30} y={388.8}>
                                        {"Please bring drinks or tapas to share."}
                                    </tspan>
                                    <tspan x={90} y={432}>
                                        {"446 Warren Drive #5"}
                                    </tspan>
                                    <tspan x={112} y={475.2}>
                                        {"San Francisco, CA"}
                                    </tspan>
                                </tspan>
                            </text>
                            <text className="cls-13" transform="translate(250 775.82)">
                                <tspan letterSpacing="-.05em">{"You're Invited"}</tspan>
                            </text>
                            <text
                                transform="translate(256.75 395.36)"
                                fontSize="120px"
                                fontFamily="Rumba-Large,Rumba"
                                fill="#ba2320"
                            >
                                <tspan className="cls-35">{"Tapas,"}</tspan>
                                <tspan className="cls-35" x={-92.16} y={144}>
                                    {"Tamales y"}
                                </tspan>
                                <tspan x={-20.76} y={288} letterSpacing="-.07em">
                                    {"Tequila"}
                                </tspan>
                            </text>
                        </g>
                    </svg>
                </ContainerSVG>
                <MobileContainer>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1125 2436">
                    <defs>
                        <style>
                        {
                            ".cls-2{fill:#e5e5e5}.cls-3{fill:#f3e7d9}.cls-4{fill:#0b674e}.cls-5{fill:#ba2320}.cls-6{fill:#e6a737}.cls-7{fill:#eb5e31}.cls-8{fill:#70c5ca}.cls-12{font-family:Rumba-Large,Rumba;font-size:60px;fill:#002923}.cls-13{letter-spacing:-.02em}.cls-14{letter-spacing:-.01em}.cls-20{letter-spacing:.02em}.cls-23{letter-spacing:-.02em}.cls-24{letter-spacing:-.03em}.cls-31{letter-spacing:-.06em}.cls-37{fill:#101012}.cls-38{fill:#b57627}.cls-39{fill:#f5f5e9}"
                        }
                        </style>
                    </defs>
                    <path fill="#e2ddd7" id="Background" d="M-10 -8H1137V2448H-10z" />
                    
                    <g id="Snowflakes">
                        <animate attributeName="opacity"
                            attributeType="XML"
                            values="0.3; 1; 0.3"
                            dur="4s"
                            repeatCount="indefinite"
                        />
                        <path
                        className="cls-2"
                        d="M-106.98 929.46L-78.18 806.06 7.4 759.6 -108.21 738.28 -218.67 1206.91 34.26 849.96 -23.04 822.01 -15.33 860.06 -106.98 929.46z"
                        />
                        <path
                        className="cls-2"
                        d="M-11.6 1498.7L55.8 1606.01 151.84 1622.07 49.46 1679.87 -207.53 1272.72 147.82 1527.89 102.74 1572.97 97.64 1534.48 -11.6 1498.7z"
                        />
                        <path
                        className="cls-2"
                        d="M-106.04 1557.2L-39.15 1664.82 -66.81 1758.19 30.01 1691.51 -223.25 1282.04 -149.7 1713.29 -89.42 1692.53 -121.78 1671.08 -106.04 1557.2z"
                        />
                        <path
                        className="cls-2"
                        d="M102.81 1268.77L226.34 1296.98 305.62 1240.43 274.1 1353.69 -195.52 1247.52 236.18 1176.67 236.18 1240.43 205.36 1216.82 102.81 1268.77z"
                        />
                        <path
                        className="cls-2"
                        d="M77.4 1376.91L200.8 1405.71 247.26 1491.29 268.57 1375.68 -200.05 1265.22 156.9 1518.15 184.85 1460.85 146.79 1468.56 77.4 1376.91z"
                        />
                        <path
                        className="cls-2"
                        d="M18.7 1022.11L126 954.72 142.07 858.67 199.86 961.05 -207.28 1218.04 47.88 862.69 92.97 907.77 54.48 912.87 18.7 1022.11z"
                        />
                        <path
                        className="cls-2"
                        d="M77.19 1116.55L184.81 1049.66 278.18 1077.33 211.5 980.5 -197.97 1233.76 233.28 1160.21 212.53 1099.93 191.07 1132.29 77.19 1116.55z"
                        />
                        <path
                        className="cls-3"
                        d="M-141.22 16.23L60.05 129.31 60.05 -89.06 40.57 -8.23 -108.61 -217.24 -40.58 46.63 -141.22 16.23z"
                        />
                        <path
                        className="cls-3"
                        d="M74.65 -16.93L74.84 129.46 195.48 61.48 144.76 75.88 213.78 -71.61 89.19 48.13 74.65 -16.93z"
                        />
                        <path
                        className="cls-3"
                        d="M281.17 28.89L79.04 140.42 264.67 255.43 206.22 196.3 462.46 179.57 202.33 98.42 281.17 28.89z"
                        />
                        <path
                        className="cls-3"
                        d="M271.68 270.53L70.41 157.45 70.41 375.82 89.89 294.99 239.07 504 171.05 240.13 271.68 270.53z"
                        />
                        <path
                        className="cls-3"
                        d="M55.81 303.69L55.62 157.3 -65.02 225.28 -14.29 210.88 -83.32 358.37 41.28 238.63 55.81 303.69z"
                        />
                        <path
                        className="cls-3"
                        d="M-150.71 257.87L51.42 146.34 -134.21 31.33 -75.75 90.46 -332 107.19 -71.86 188.34 -150.71 257.87z"
                        />
                        <path
                        className="cls-2"
                        d="M1059.75 -155.5L1009.87 -234.9 938.8 -246.79 1014.56 -289.56 1204.73 11.73 941.77 -177.1 975.13 -210.46 978.9 -181.98 1059.75 -155.5z"
                        />
                        <path
                        className="cls-2"
                        d="M1129.63 -198.79L1080.13 -278.43 1100.6 -347.52 1028.95 -298.18 1216.37 4.83 1161.94 -314.29 1117.33 -298.93 1141.28 -283.06 1129.63 -198.79z"
                        />
                        <path
                        className="cls-2"
                        d="M975.08 14.65L883.67 -6.23 825 35.62 848.33 -48.19 1195.85 30.38 876.38 82.8 876.38 35.62 899.19 53.1 975.08 14.65z"
                        />
                        <path
                        className="cls-2"
                        d="M993.88 -65.37L902.57 -86.68 868.19 -150.01 852.41 -64.46 1199.2 17.28 935.06 -169.89 914.37 -127.49 942.53 -133.19 993.88 -65.37z"
                        />
                        <path
                        className="cls-2"
                        d="M1037.33 197.18L957.92 247.05 946.03 318.13 903.26 242.37 1204.55 52.19 1015.73 315.15 982.37 281.79 1010.85 278.02 1037.33 197.18z"
                        />
                        <path
                        className="cls-2"
                        d="M994.04 127.29L914.4 176.79 845.31 156.32 894.65 227.97 1197.66 40.56 878.53 94.98 893.89 139.59 909.77 115.65 994.04 127.29z"
                        />
                        <path
                        className="cls-2"
                        d="M1130.33 265.74L1109.02 357.06 1045.68 391.44 1131.24 407.21 1212.98 60.43 1025.81 324.57 1068.21 345.25 1062.51 317.1 1130.33 265.74z"
                        />
                        <path
                        className="cls-3"
                        d="M1421.43 1747.92L1212.12 1468.38 1078.57 1770.52 1154.96 1670.59 1233.54 2051 1300.79 1644.32 1421.43 1747.92z"
                        />
                        <path
                        className="cls-3"
                        d="M1057.42 1784.17L1184.8 1459.02 857.63 1504.63 982.81 1516.93 700.83 1784.09 1081.95 1627.05 1057.42 1784.17z"
                        />
                        <path
                        className="cls-3"
                        d="M962.65 1467.69L1181.94 1436.83 1054.88 1270.29 1087.06 1343.27 851.68 1270.67 1057.09 1432.32 962.65 1467.69z"
                        />
                        <path
                        className="cls-3"
                        d="M988.16 1148.71L1197.47 1428.24 1331.02 1126.11 1254.63 1226.04 1176.05 845.63 1108.8 1252.31 988.16 1148.71z"
                        />
                        <path
                        className="cls-2"
                        d="M1022.85 2117.37L1033.55 2070.52 1012.1 2040.45 1055.05 2052.41 1014.78 2230.51 987.92 2066.79 1012.1 2066.79 1003.14 2078.47 1022.85 2117.37z"
                        />
                        <path
                        className="cls-2"
                        d="M1063.86 2127L1074.78 2080.21 1107.23 2062.59 1063.39 2054.5 1021.5 2232.22 1117.42 2096.86 1095.69 2086.26 1098.61 2100.69 1063.86 2127z"
                        />
                        <path
                        className="cls-2"
                        d="M929.3 2149.27L903.74 2108.57 867.32 2102.48 906.14 2080.56 1003.61 2234.97 868.84 2138.2 885.94 2121.1 887.87 2135.7 929.3 2149.27z"
                        />
                        <path
                        className="cls-2"
                        d="M965.12 2127.08L939.75 2086.27 950.24 2050.86 913.52 2076.15 1009.57 2231.43 981.68 2067.89 958.82 2075.76 971.09 2083.9 965.12 2127.08z"
                        />
                        <path
                        className="cls-2"
                        d="M885.91 2236.47L839.07 2225.77 809 2247.21 820.96 2204.26 999.05 2244.53 835.33 2271.39 835.33 2247.21 847.02 2256.17 885.91 2236.47z"
                        />
                        <path
                        className="cls-2"
                        d="M895.55 2195.46L848.75 2184.53 831.13 2152.08 823.05 2195.92 1000.77 2237.81 865.4 2141.89 854.8 2163.62 869.23 2160.7 895.55 2195.46z"
                        />
                        <path
                        className="cls-2"
                        d="M917.81 2330.01L877.12 2355.57 871.03 2391.99 849.11 2353.17 1003.51 2255.71 906.75 2390.47 889.65 2373.37 904.25 2371.44 917.81 2330.01z"
                        />
                        <path
                        className="cls-2"
                        d="M895.63 2294.19L854.82 2319.56 819.41 2309.07 844.69 2345.79 999.98 2249.74 836.43 2277.64 844.31 2300.5 852.44 2288.22 895.63 2294.19z"
                        />
                        <path
                        className="cls-2"
                        d="M1006.49 2374.78L995.79 2421.63 1017.23 2451.7 974.28 2439.74 1014.55 2261.65 1041.41 2425.36 1017.23 2425.36 1026.19 2413.68 1006.49 2374.78z"
                        />
                        <path
                        className="cls-2"
                        d="M965.48 2365.15L954.55 2411.95 922.1 2429.56 965.94 2437.65 1007.83 2259.93 911.91 2395.3 933.64 2405.9 930.72 2391.46 965.48 2365.15z"
                        />
                        <path
                        className="cls-2"
                        d="M1100.03 2342.89L1125.59 2383.58 1162.01 2389.67 1123.19 2411.59 1025.73 2257.18 1160.49 2353.95 1143.39 2371.05 1141.46 2356.45 1100.03 2342.89z"
                        />
                        <path
                        className="cls-2"
                        d="M1064.21 2365.07L1089.58 2405.88 1079.09 2441.29 1115.81 2416.01 1019.76 2260.72 1047.66 2424.26 1070.52 2416.39 1058.25 2408.26 1064.21 2365.07z"
                        />
                        <path
                        className="cls-2"
                        d="M1143.42 2255.68L1190.27 2266.39 1220.33 2244.94 1208.38 2287.89 1030.28 2247.62 1194 2220.76 1194 2244.94 1182.31 2235.98 1143.42 2255.68z"
                        />
                        <path
                        className="cls-2"
                        d="M1133.78 2296.7L1180.58 2307.62 1198.2 2340.07 1206.28 2296.23 1028.56 2254.34 1163.93 2350.26 1174.53 2328.53 1160.1 2331.45 1133.78 2296.7z"
                        />
                        <path
                        className="cls-2"
                        d="M1111.52 2162.14L1152.21 2136.58 1158.31 2100.16 1180.22 2138.98 1025.82 2236.45 1122.59 2101.68 1139.68 2118.78 1125.09 2120.71 1111.52 2162.14z"
                        />
                        <path
                        className="cls-2"
                        d="M1133.7 2197.96L1174.52 2172.59 1209.93 2183.08 1184.64 2146.36 1029.35 2242.41 1192.9 2214.52 1185.03 2191.65 1176.89 2203.93 1133.7 2197.96z"
                        />
                        <path
                        className="cls-3"
                        d="M16.99 2081.26L20.89 2309.34 207.18 2200.44 128.51 2224.14 232.41 1992.64 41.24 2182.27 16.99 2081.26z"
                        />
                        <path
                        className="cls-3"
                        d="M152.93 2248.87L28.14 2322.04 146.3 2391.04 108.72 2354.96 268.95 2340.29 104.68 2293.71 152.93 2248.87z"
                        />
                        <path
                        className="cls-3"
                        d="M216.83 2447.89L20.89 2331.08 15.35 2546.8 36.64 2467.44 178.7 2677.69 118.2 2415.31 216.83 2447.89z"
                        />
                        <path
                        className="cls-3"
                        d="M5.97 2560.3L2.06 2332.22 -184.23 2441.12 -105.56 2417.42 -209.46 2648.92 -18.28 2459.29 5.97 2560.3z"
                        />
                        <path
                        className="cls-3"
                        d="M-193.88 2193.67L2.06 2310.48 7.6 2094.76 -13.69 2174.12 -155.75 1963.87 -95.25 2226.25 -193.88 2193.67z"
                        />
                    </g>
                    <path
                        d="M206 50C164 60.78 81 82.1 68 135c-9.87 40.2 26.31 67.28 69 133 62.39 96 103.4 256.57 41 338-35.75 46.66-107.49 69.19-128.5 49.5-23-21.52 5-102.46 28-103 28-.66 72.65 117.94 62 231-11.77 124.91-82.66 154.49-95 264-13.72 121.7 88.26 135.93 82 256-6 114.94-91.25 146-74 254 8.83 55.26 43.08 121.75 79 121 30-.62 62.48-48.25 52-69-14.25-28.22-122.2-24.85-147 20-36.29 65.63 134.32 166.9 103 286-17.38 66.07-81.33 78.4-79 142 1.57 42.89 32.23 49 64 120 27 60.29 45.93 146.81 21 162-20.91 12.73-75.43-23-72-45 3.62-23.22 72.41-34.59 120-34 127.31 1.58 144 91.29 254 102 115.29 11.22 143.48-77.36 287-66 62.84 5 150.28 29.3 154 66 3 29.95-49.51 70-75 59-34-14.66-40.38-129.13 8-166 57.63-43.93 156.92 50.6 203 13 46.41-37.87-42.16-143.51-37-319 5.3-180.33 103.19-217.29 72-356-25.28-112.43-107.75-169.53-82-205 21.76-30 105.52-21.78 116 8 8.66 24.59-21.23 68.9-41 66-30.75-4.52-53.13-127.52-30-231 6.84-30.61 14-43.12 23-91 12.88-68.53 11.54-113.87 11-127-1.69-40.89-2.63-63.53-14-91-21.13-51.06-72.29-104.27-106-93-23.73 7.94-39.2 47.89-28 62 16.31 20.55 107.39 5 139-44 67.35-104.27-181.73-287.72-116-462 25.84-68.5 80-81.69 95-169 14.55-84.59-56.57-147.91-31-174 17.84-18.21 66.7-7.15 70 8 4.25 19.54-63.43 63.52-131 74-109.79 17-143.31-66.18-293-77-24.55-1.77-148.95-10.76-182 51-14.09 26.33-11.5 65.39 3 72 20.18 9.2 77.62-37.94 72-72C525.39 43.29 349.05 13.27 206 50z"
                        fill="none"
                        stroke="#658080"
                        strokeMiterlimit={10}
                        strokeWidth="5px"
                        />
                    <g id="Lights">
                        <animate attributeName="opacity"
                            attributeType="XML"
                            values="1; 0.6; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        />
                        <path
                        className="cls-4"
                        d="M1166.22 1201s-65.12-56.72-124.19-46.2l-8.63 36s17.87 33 132.82 10.2zM181.66 1088.14s-123.67 34-118 60.32l20.69 30.67s83.32-35.69 97.31-90.99zM424 2215.58s-66.95 33.28-88.19 98.9l30.39 21.11s54.8-35.01 57.8-120.01z"
                        />
                        <path
                        className="cls-5"
                        d="M966.69 1029.64s34.38-79.22 6.49-132.35L936.27 900s-26.09 27 30.42 129.64zM-39.78 1382.79s89.78 91.56 110.05 73.79l16.75-33s-71.67-55.43-126.8-40.79zM704.89 2426.76s32.47-67.35 9-132.2l-36.95 2s-21.78 61.19 27.95 130.2z"
                        />
                        <path
                        className="cls-6"
                        d="M1070.63 2388.86s4.83-86.22-39.73-126.41c-13.9 15.55-33.7 15.28-33.7 15.28s-15.13 34.38 73.43 111.13zM246.46 197.32s-123.78 33.59-118.17 59.93L148.88 288s83.4-35.42 97.58-90.68zM226.63 1774.92s-5.32-74.58-57.92-119.17l-31.08 20.06s11.54 63.97 89 99.11z"
                        />
                        <path
                        className="cls-7"
                        d="M5.05 792.68s68.64 52.4 126.95 38.09l6.29-36.46s-20-31.82-133.24-1.63zM917.64 1583.5s126.87-18.83 124.39-45.65l-16.84-32.94s-86.98 25.37-107.55 78.59zM14 2428.57s68.91-29 94.22-93.17l-29-23S22.31 2343.92 14 2428.57z"
                        />
                        <path
                        className="cls-8"
                        d="M194 479s-84.34 18.52-111.07 72.24l23.76 28.36s37.2 5.28 87.31-100.6zM1119.62 1984.31s-124.09-32.48-132.33-6.85l2.56 36.92s89.96 10.8 129.77-30.07zM8.72 1885.63s26.36 70 89.5 97.71l24.07-28.1S93 1897.22 8.72 1885.63z"
                        />
                        <path
                        className="cls-6"
                        d="M868.91 693.69S995.28 671.78 992.16 645l-17.64-32.52S888.18 640 868.91 693.69z"
                        />
                        <path
                        className="cls-7"
                        d="M923.4-7.56S905.72 77 943.77 123.37l35.61-10.06s20.1-31.73-55.98-120.87z"
                        />
                        <path
                        className="cls-8"
                        d="M1088.38 385.65s-33.85-79.45-91.6-95.75l-23.45 28.62S975 356 1088.38 385.65z"
                        />
                        <path
                        className="cls-4"
                        d="M114.4-59.11s3 128.23 29.87 129.09l34.78-12.63S164.65-32.1 114.4-59.11z"
                        />
                        <path
                        className="cls-5"
                        d="M604 177.32S641.74 54.74 616.48 45.4l-37 1S564.85 135.8 604 177.32z"
                        />
                    </g>
                    <g id="Text">
                        <text
                        transform="translate(450 2205.5)"
                        fontFamily="Rumba-Large,Rumba"
                        fontSize="90px"
                        fill="#ba2320"
                        className="reply"
                        >
                        <RSVP href="mailto:matthew@mreyes.info?subject=Holiday Party&body=Thanks for the invite, I'll be there! Sounds like fun.">{"RSVP"}</RSVP>
                        </text>
                        <text className="cls-12" transform="translate(320 1628)">
                        {"Join us to celebrate"}
                        <tspan x={10} y={72}>
                            {"the holiday season"}
                        </tspan>
                        <tspan fontSize="72px" fill="#ba2320">
                            <tspan x={-45} y={230.4}>
                            {"December 11, 2021"}
                            </tspan>
                        </tspan>
                        <tspan letterSpacing="-.03em" fontSize="72px" fill="#ba2320">
                            <tspan x={150} y={316.8}>
                            {"6:15pm"}
                            </tspan>
                        </tspan>
                        <tspan fontSize="36px">
                            <tspan x={-40} y={432}>
                            {"Please bring drinks or tapas to share."}
                            </tspan>
                            <tspan x={-65} y={475.2}>
                            {"446 Warren Drive #5, San Francisco, CA"}
                            </tspan>
                        </tspan>
                        </text>
                        <text className="cls-12" transform="translate(410 745.31)">
                        <tspan letterSpacing="-.05em">{"You're Invited"}</tspan>
                        </text>
                        <text
                        transform="translate(260 332.85)"
                        fontSize="120px"
                        fontFamily="Rumba-Large,Rumba"
                        fill="#ba2320"
                        >
                        <tspan className="cls-31">{"Tapas,"}</tspan>
                        <tspan className="cls-31" x={100} y={144}>
                            {"Tamales y"}
                        </tspan>
                        <tspan x={300} y={288} letterSpacing="-.07em">
                            {"Tequila"}
                        </tspan>
                        </text>
                    </g>
                    <g id="Dog">
                        <path
                        className="cls-37"
                        d="M486 1053s5-36.46-20-43.23-63 6.23-95 62.23c9 32 11 85 8 130 48-6 36-123 36-123s48.51-35 71-26zM740 1346c-37-105-148-93-160-114 47-39 7-75-3-77-94.11-18-65-54-76-74-13-64 28-78 42-84 96-34 169 13 169 13 31 30-6 47 16 99-16 44 11 73 16 93 4 16 6 104-4 144zM460 1160s27-24 59-4c1 26-33 70-59 4z"
                        />
                        <path
                        className="cls-37"
                        d="M486 1053s16 69-33 102c-4 38-21 46 15 101-30 39-53 58-32 87-33-10-65-72-57-141 8-50 13-98 39-128 19-19 48-40 68-21z"
                        />
                        <path
                        className="cls-37"
                        d="M497 1211s-17 16-31 21c14 2 24-7.15 31-8.08 29 2.16 45.46 7.65 67.23 8.36C536.77 1220.72 512 1221 497 1211z"
                        />
                        <path
                        className="cls-38"
                        d="M555 1074s37.12-12 63.56 0c-8.12-19-16.56-17-16.56-17s-18 2-47 17zM466 1093.64s-10-11.64-29 7.36c11-17 14.5-19 14.5-19s13.5 5.27 14.5 11.64zM658 1166s11 54-29 62c70 20 60 11 83 28-2-30-38-58-54-90zM436 1216s12 28 26 39c-24 11-39 18-47 25-11-21 11.5-37.72 21-64z"
                        />
                        <path
                        className="cls-37"
                        d="M499.5 1436.5s18 45.73 63 88.86c-15-32.72-25.34-31.6-15-35.86 8.5-3.5-48-53-48-53zM583 1541s7-53 110-121c-46 41-74 65-110 121z"
                        />
                    </g>
                    <g id="Hat">
                        <path
                        className="cls-5"
                        d="M334.12 991.13S539 749 724 843c0 0 38 14 53 111 5.43 35.09 50.5 147.5 50.5 147.5s-50.15 50-104.08-50.52c-.59-1.1 3.58-29-3.42-26-52.4-96.76-61.5-53.5-61.5-53.5z"
                        />
                        <path
                        className="cls-39"
                        d="M360.63 1076.92s83.46-109.34 362.16-51.92c39.42 10.92 69.41-26.87 41.56-45.2s-267-128.21-458.94 33.23c-2.82 49.9 1.34 62.32 55.22 63.89zM827.5 1101.5S812 1091 791 1117s5.85 48.33 5.85 48.33S850 1199 854 1162s-4-52-26.5-60.5z"
                        />
                    </g>
                    </svg>
                </MobileContainer>
            </>
		);
};

export default CardPage2021;

const flash = keyframes`
    from, 20%, 40%, 60%, 80% to {
        fill: none;
    }
`;

const ContainerSVG = styled.div`
    display: none;
    ${mediaQuery[4]} {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
`;

const MobileContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    ${mediaQuery[4]} {
        display: none;
    }
`;

const colorFlash = css`
    .color {
        animation: ${flash} 2s ease infinite;
    }
`;

const svgContent = css`
    font-family: "Rumba-Large,Rumba";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    .cls-2{
        fill:#f3e7d9
    }
    .cls-3{
        fill:#e5e5e5
    }
    .cls-4{
        fill:#0b674e
    }
    .cls-5{
        fill:#ba2320
    }
    .cls-6{
        fill:#e6a737
    }
    .cls-7{
        fill:#eb5e31
    }
    .cls-8{
        fill:#70c5ca
    }
    .cls-10{
        fill:#101012
    }
    .cls-11{
        fill:#b57627
    }
    .cls-12{
        fill:#f5f5e9
    }
    .cls-13{
        font-size:60px;
        fill:#002923;
        font-family:Rumba-Large,Rumba;
        :hover {
            fill: #70c5ca;
        }
        :focus {
            fill: #70c5ca;
        }
        :active {
            fill: #eb5e31;
        }

    }
    .cls-14, .cls-27{
        letter-spacing:-.02em;
    }
    .cls-35{
        letter-spacing:-.06em;
    }
`;

const RSVP = styled.a`
    color: #002923;
    :hover {
        color: #eb5e31;
    }
    :focus {
        color: #eb5e31;
    }
    :active {
        color: #70c5ca;
    }
`;